<template>
  <div class="bg-white px-2 pt-3 pb-4 relative">
    <h2 class="font-bold text-mdl text-grey-light mb-1.5">Product Drafts</h2>
    <p class="font-normal text-md text-grey-dark-1 mb-2">
      Products that are in the process of being created and are not available to
      customers yet.
    </p>
    <p
      v-if="drafts.length === 0"
      class="text-mdlh text-grey-dark-1 font-medium mb-1 ml-2"
    >
      No Drafts added yet
    </p>
    <AssessmentCardsContainer
      id="drafts"
      :cards="drafts"
      :showProductsCount="false"
      :cardsCount="drafts.length"
      class="container"
    >
      <ProductCard
        v-for="survey in drafts"
        :key="survey.id"
        :survey="survey"
        :isDraft="true"
        :onClick="() => continueDraft(survey.id)"
        :onRemove="() => deleteDraft(survey.id)"
        buttonText="Continue"
        class="flex-shrink-0"
      >
        <p
          v-if="survey.scheduledLaunchDate"
          class="text-blue-light-5 text-sm-2 mb-0.5"
        >
          Launching {{ survey.scheduledLaunchDate }}
          <span
            class="font-bold cursor-pointer underline"
            @click="() => editLaunch(survey.id)"
            >Edit.</span
          >
        </p>
        <p v-if="survey.latestVersion" class="text-blue-light-5 text-sm-2">
          Latest Version: {{ survey.latestVersion }}
        </p>
      </ProductCard>
    </AssessmentCardsContainer>
    <div class="absolute right-2 top-3">
      <Button
        type="primary"
        size="medium"
        text="New Product"
        :iconLeft="plus"
        @click.native="createProduct"
      />
    </div>
  </div>
</template>
<script>
import plus from "@/assets/img/icons/add.svg";
import AssessmentCardsContainer from "../../AdminAssessments/components/AssessmentCardsContainer.vue";
import ProductCard from "./ProductCard.vue";
import Button from "../../../components/Button/Button.vue";
import { mapActions, mapState } from "vuex";

export default {
  name: "DraftProducts",
  components: { AssessmentCardsContainer, ProductCard, Button },
  data: () => ({ plus }),
  computed: {
    ...mapState({
      drafts: (state) => state.products.drafts,
    }),
  },
  methods: {
    ...mapActions({
      getDrafts: "products/getDrafts",
      openModal: "modals/openModal",
      resetProductDetails: "products/resetProductDetails",
    }),
    createProduct() {
      this.resetProductDetails();
      this.$router.push("/products/new-product/details");
    },
    continueDraft(id) {
      this.resetProductDetails();
      this.$router.push(`/products/${id}/dashboard`);
    },
    deleteDraft(id) {
      this.openModal({
        modal: "deleteProductModal",
        props: { id, isProductsView: true },
      });
    },
    editLaunch(id) {
      this.continueDraft(id);
      this.openModal({ modal: "scheduleProductLaunchModal" });
    },
  },
  mounted() {
    this.getDrafts();
  },
};
</script>
<style lang="scss">
.container {
  margin-bottom: 0 !important;
}
</style>
