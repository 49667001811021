<template>
  <div>
    <p class="text-xl font-headers mb-3 font-bold text-grey-dark-3">
      Products
    </p>
    <LaunchedProducts class="mb-2" />
    <DraftProducts class="mb-2" />
    <VersionHistoryProducts class="mb-2"/>
    <RetiredProducts />
  </div>
</template>

<script>
import DraftProducts from "../components/DraftProducts.vue";
import LaunchedProducts from "../components/LaunchedProducts.vue";
import VersionHistoryProducts from "../components/VersionHistoryProducts.vue";
import RetiredProducts from "../components/RetiredProducts.vue"

export default {
  name: "ProductsView",
  components: { LaunchedProducts, DraftProducts, VersionHistoryProducts, RetiredProducts },
};
</script>
