<template>
    <div class="relative mb-4">
        <p v-if="title" class="font-medium text-grey-dark-1 text-mdlh mb-1">{{ title }} Assessments</p>
        <p v-if="showProductsCount" class="text-grey-dark-2 text-sm-2 mb-2">
            {{ cardsCount + ' Product' + (cardsCount > 1 ? 's' : '') }}
        </p>
        <div class="flex flex-row space-x-2 overflow-y-scroll items-center" :id="`assessmentContainer-${title}-${this.id}`">
            <button
                class="buttonShadow w-6 h-6 rounded-full bg-white flex justify-center items-center transform -translate-x-1/2 absolute z-10 hover:bg-grey-light-3 active:bg-grey-faded-3"
                id="scrollLeft"
                v-if="canScrollLeft"
                @click="left"
            >
                <img src="@/assets/img/icons/arrow-right.svg" class="w-3 h-3 transform rotate-180 -translate-x-0.2" />
            </button>
            <div class="flex flex-row space-x-2 max-w-0">
                <slot />
            </div>
            <button
                class="buttonShadow w-6 h-6 rounded-full absolute bg-white flex justify-center items-center -right-4 z-10  hover:bg-grey-light-3 active:bg-grey-faded-3"
                :style="`transform: translateX(-24px);`"
                id="scrollRight"
                v-if="canScrollRight"
                @click="right"
            >
                <img src="@/assets/img/icons/arrow-right.svg" class="w-3 h-3 transform translate-x-0.2" />
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Container',
    props: {
        cardsCount: { type: Number, default: 0 },
        title: { type: String },
        showProductsCount: {type: Boolean, default: true},
        id: { type: String, default: '' },
    },
    data: () => ({
        containerPositioning: 0,
        container: null,
        canScrollLeft: false,
        canScrollRight: false,
        isScrollingLeft: false,
        isScrollingRight: false,
    }),
    methods: {
        getContainerSize() {
            this.containerPositioning = this.container.getBoundingClientRect().left;
            this.checkScroll();
        },
        checkScroll() {
            this.canScrollLeft = this.container.scrollLeft !== 0;
            this.canScrollRight =
                274 * this.cardsCount - this.container.getBoundingClientRect().width > this.container.scrollLeft;
        },
        left() {
            if (!this.isScrollingRight) {
                this.isScrollingLeft = true;
                let previous = this.container.scrollLeft;
                const i = setInterval(() => {
                    this.container.scrollLeft -= 5;
                    this.checkScroll();
                    if (
                        !this.canScrollLeft ||
                        this.container.scrollLeft <= previous - 349 ||
                        this.container.scrollLeft === 0
                    ) {
                        clearInterval(i);
                        this.isScrollingLeft = false;
                    }
                }, 1);
            }
        },
        right() {
            if (!this.isScrollingLeft) {
                this.isScrollingRight = true;
                let previous = this.container.scrollLeft;
                const i = setInterval(() => {
                    let currentScroll = this.container.scrollLeft;
                    this.container.scrollLeft += 5;
                    this.checkScroll();
                    if (
                        !this.canScrollRight ||
                        this.container.scrollLeft >= previous + 349 ||
                        currentScroll === this.container.scrollLeft
                    ) {
                        clearInterval(i);
                        this.isScrollingRight = false;
                    }
                }, 1);
            }
        },
    },
    mounted() {
        this.container = document.getElementById(`assessmentContainer-${this.title}-${this.id}`);
        window.addEventListener('resize', this.getContainerSize);
        this.$nextTick(() => {
            this.getContainerSize();
        })
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.getContainerSize);
    },
};
</script>

<style lang="scss" scoped>
.buttonShadow {
    filter: drop-shadow(0px 4px 16px rgba(0, 82, 149, 0.3));
}
</style>
