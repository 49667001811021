<template>
  <div class="bg-white px-2 pt-3 pb-4 relative">
    <h2 class="font-bold text-mdl text-grey-light mb-1.5">Retired Products</h2>
    <p class="font-normal text-md text-grey-dark-1 mb-2">
      Products that have been retired and are no longer availabile. View retired products here.
    </p>
    <p
      v-if="retiredProducts.length === 0"
      class="text-mdlh text-grey-dark-1 font-medium mb-1 ml-2"
    >
      No products retired yet
    </p>
    <AssessmentCardsContainer
      id="retired-products"
      :showProductsCount="false"
      :cardsCount="retiredProducts.length"
      class="container"
    >
      <ProductCard
        v-for="survey in retiredProducts"
        :key="survey.id"
        :survey="survey"
        :onClick="() => view(survey.id)"
        buttonText="View"
        class="flex-shrink-0"
      >
        <p class="text-blue-light-5 text-sm-2">
         Retired on  {{ survey.retiredAt }} 
        </p>
      </ProductCard>
    </AssessmentCardsContainer>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

import AssessmentCardsContainer from "../../AdminAssessments/components/AssessmentCardsContainer.vue";
import ProductCard from "./ProductCard.vue";

export default {
  name: "RetiredProducts",
  components: { AssessmentCardsContainer, ProductCard },
  computed: {
    ...mapState({
      retiredProducts: (state) => state.products.retiredProducts,
    }),
  },
  methods: {
    ...mapActions({
      getRetiredProducts: "products/getRetiredProducts",
      resetProductDetails: "products/resetProductDetails",
    }),
    view(id) {
      this.resetProductDetails();
      this.$router.push(`/products/${id}/dashboard`);
    },
  },
  async mounted() {
    await this.getRetiredProducts();
  },
};
</script>
