<template>
  <div class="bg-white px-2 pt-3 pb-4 relative">
    <h2 class="font-bold text-mdl text-grey-light mb-1.5">Version History</h2>
    <p class="font-normal text-md text-grey-dark-1 mb-2">
      Past Products that have new versions launched. View version history here.
    </p>
    <p
      v-if="versionHistoryProducts.length === 0"
      class="text-mdlh text-grey-dark-1 font-medium mb-1 ml-2"
    >
      No Version History added yet
    </p>
    <AssessmentCardsContainer
      id="version-history-products"
      :showProductsCount="false"
      :cardsCount="versionHistoryProducts.length"
      class="container"
    >
      <ProductCard
        v-for="survey in versionHistoryProducts"
        :key="survey.id"
        :survey="survey"
        :onClick="() => view(survey.id)"
        buttonText="View"
        class="flex-shrink-0"
      >
        <p v-if="survey.versionCount" class="text-blue-light-5 text-sm-2">
          {{ survey.versionCount }} Versions
        </p>
      </ProductCard>
    </AssessmentCardsContainer>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

import AssessmentCardsContainer from "../../AdminAssessments/components/AssessmentCardsContainer.vue";
import ProductCard from "./ProductCard.vue";

export default {
  name: "VersionHistoryProducts",
  components: { AssessmentCardsContainer, ProductCard },
  computed: {
    ...mapState({
      versionHistoryProducts: (state) => state.products.versionHistoryProducts,
    }),
  },
  methods: {
    ...mapActions({
      getVersionHistoryProducts: "products/getVersionHistoryProducts",
      resetProductDetails: "products/resetProductDetails",
    }),
    view(id) {
      this.resetProductDetails();
      this.$router.push(`/products/${id}/dashboard`);
    },
  },
  async mounted() {
    await this.getVersionHistoryProducts();
  },
};
</script>
